.button-container {
    display: flex;
    justify-content: space-between; /* Ensures buttons are at opposite ends */
    width: 60%; /* Ensures the container takes up full width */
  }
  
  




  .size-container {
    display: flex;
    gap: 15px; /* Space between circles */
    margin-top: 10px;
  }
  
  .size-circle {
    width: 35px;
    height: 35px;
    background-color:rgba(155, 148, 148, 0.952);
    border-radius: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    border-color: black;
  }
  
  .size-circle:hover {
    background-color: #080808; /* Color change on hover */
    color: aliceblue;
  }
  
  .size-circle.selected {
    background-color: rgb(10, 10, 10); /* The fixed color for the selected size */
    cursor: default; /* Disables hover cursor */
    color: aliceblue;
  }
  
  /* Disable hover effect for the selected size */
  .size-circle.selected:hover {
    background-color: grey; /* Same color to prevent hover change */
  }