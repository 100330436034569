.invite-code-form {
    position: fixed; /* Fixed position to ensure it stays in the center */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center it perfectly */
    width: 90%; /* Adjust width to fit on smaller screens */
    max-width: 400px; /* Max width for larger screens */
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 100; /* Ensure it's above other elements */
  }
  
  .invite-code-input {
    width: 100%; /* Full width input */
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
  }
  
  .invite-code-submit {
    width: 100%;
    padding: 10px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  