.product-table-container {
    padding: 20px;
    overflow-x: auto; /* Makes table scrollable horizontally on smaller screens */
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .product-table th,
  .product-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    vertical-align: middle;
  }
  
  .product-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .product-table img {
    border-radius: 8px;
  }
  