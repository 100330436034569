/* src/components/MyOrders.css */

.my-orders {
    max-width: 1300px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column; /* Stack orders vertically on smaller screens */
    gap: 20px;
  }
  
  .page-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .order-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  .order-id {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4a4a4a;
  }
  
  .order-date {
    font-size: 0.9rem;
    color: #888;
  }
  
  .order-summary {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 1rem;
    color: #555;
    flex-wrap: wrap; /* Adjusts layout on smaller screens */
  }
  
  .product-list {
    margin-top: 1rem;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.5rem;
  }
  
  .product-table th,
  .product-table td {
    padding: 0.8rem;
    text-align: left;
    border: 1px solid #e0e0e0;
  }
  
  .product-table th {
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
  }
  
  .product-table td {
    font-size: 0.95rem;
    color: #555;
  }
  
  .loading {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
  }
  
  .error-message {
    text-align: center;
    font-size: 1.2rem;
    color: #e74c3c;
  }

  .cancel-order-button {
    margin-top: 15px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #f44336;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-order-button:hover {
    background-color: #d32f2f;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .my-orders {
      padding: 1rem;
    }
  
    .page-title {
      font-size: 1.5rem;
    }
  
    .order-card {
      padding: 1rem;
    }
  
    .order-id {
      font-size: 1rem;
    }
  
    .order-summary p {
      flex: 1 1 100%; /* Full width on smaller screens */
      margin-bottom: 0.5rem;
    }
  
    .product-table th,
    .product-table td {
      padding: 0.5rem;
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 480px) {
    .page-title {
      font-size: 1.3rem;
    }
  
    .order-id {
      font-size: 0.9rem;
    }
  
    .order-date,
    .order-summary p {
      font-size: 0.85rem;
    }
  
    .product-table th,
    .product-table td {
      padding: 0.4rem;
      font-size: 0.8rem;
    }
  }
  