.banner {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent overflow */
    position: relative; /* Positioning context for children */
  }
  
  .slider {
    width: 100%; /* Ensure the slider takes full width */
    height: 100%; /* Ensure the slider takes full height */
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  
  .shimmer {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 2000px 100%;
    animation: shimmer 1.5s infinite;
  }
  