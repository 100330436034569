.blinking-badge {
    display: inline-block;
   font-size: 16px;
   font-weight: 700;
    animation: blink 1s infinite;
    color: red;
  }

  .blinking-badge_stock {
   font-size: 16px;
   font-weight: 700;
    animation: blink 1s infinite;
    color: #767676;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  
  .shimmer {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 2000px 100%;
    animation: shimmer 1.5s infinite;
  }
  