/* Footer.css */

/* Default black color for icons */
.iconinstagram svg, .icontwitter svg, .iconlinkdin svg, .iconredditalien svg, .iconpinterest svg, .iconfacebook svg, .iconyoutube svg {
   color: black; /* Default icon color */
   font-size: 24px; /* Adjust size if needed */
   margin: 0 10px; /* Spacing between icons */
   transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for both color and size */
 }
 
 /* On hover, change the icons to their respective original colors and enlarge */
 .iconinstagram:hover svg {
   color: #E1306C; /* Instagram original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .icontwitter:hover svg {
   color: #1DA1F2; /* Twitter original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .iconlinkdin:hover svg {
   color: #0077B5; /* LinkedIn original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .iconredditalien:hover svg {
   color: #FF4500; /* Reddit original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .iconpinterest:hover svg {
   color: #BD081C; /* Pinterest original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .iconfacebook:hover svg {
   color: #4267B2; /* Facebook original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 
 .iconyoutube:hover svg {
   color: #FF0000; /* YouTube original color */
   transform: scale(1.2); /* Enlarge icon */
 }
 