/* General Styles */
.order-details {
    padding: 20px;
  }
  
  .back-button {
    background-color: #FF6F61;
    color: white;
    padding: 12px 25px;
    margin-bottom: 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #ff4b3e;
  }
  
  .order-sections {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  /* Address Section Styles */
  .order-section {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .address-section {
    padding: 30px;
    background: #f8f8f8;
    border-radius: 10px;
  }
  
  /* Section Title */
  .section-title {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 2px solid #FF6F61;
    padding-bottom: 10px;
  }
  
  /* Address Info Layout */
  .address-info {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Adds space between each label-value pair */
  }
  
  .address-row {
    display: flex;
    justify-content: space-between;
  }
  
  .address-label {
    font-weight: bold;
    color: #FF6F61;
    width: 150px; /* Set a fixed width for labels to align them */
    text-align: left;
  }
  
  .address-value {
    color: #555;
    flex: 1; /* Take up remaining space */
    word-wrap: break-word;
  }
  
  .address-row span {
    color: #555;
    word-wrap: break-word;  /* Ensures that text wraps instead of overflowing */
  }
  
  /* Products Section Table */
  .products-section table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .products-section th,
  .products-section td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .products-section th {
    background-color: #FF6F61;
    color: white;
  }
  
  .products-section td {
    background-color: #f9f9f9;
  }
  
  .products-section img {
    max-width: 50px;
    height: auto;
    border-radius: 8px;
  }
  