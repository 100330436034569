/* Main container adjustments */
.main-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

/* Sidebar styling */
.fixed-sidebar {
    position: fixed; /* Keep the sidebar fixed in place */
    top: 0;
    left: 0;
    max-height: 90vh; /* Limits sidebar height */
    overflow-y: auto; /* Scroll if content overflows */
    width: 20%; /* Default width on larger screens */
    padding: 10px;
    background-color: #f7f7f7; /* Add background for visibility */
    z-index: 10;
}

/* Hides scrollbar in Webkit (Chrome, Safari) */
.fixed-sidebar::-webkit-scrollbar {
    display: none;
}

/* Hides scrollbar in Firefox */
.fixed-sidebar {
    scrollbar-width: none;
}

/* Scrollable content styling */
.scrollable-content {
    overflow-y: auto;
    width: 80%;
    margin-left: 20%; /* Offset for fixed sidebar */
    padding: 20px;
    height: calc(100vh - 100px);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .fixed-sidebar {
        width: 25%; /* Slightly wider on medium screens */
    }
    .scrollable-content {
        width: 75%;
        margin-left: 25%;
    }
}

@media (max-width: 768px) {
    .fixed-sidebar {
        position: relative; /* Makes sidebar scroll with the page */
        width: 100%;
        max-height: none; /* Full height allowed on smaller screens */
        margin-bottom: 20px;
    }
    .scrollable-content {
        width: 100%;
        margin-left: 0;
    }
}
