@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&display=swap');

.countdown-title {
  font-size: 2.5rem;
  font-family: "Chakra Petch", sans-serif;
  margin-bottom: 20px;
  color: #333;
}

.countdown-timer {
  font-size: 4rem;
  font-family: "Chakra Petch", sans-serif;
  color: #ff5722;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  white-space: nowrap; 
}

.countdown-number {
  font-size: 4.5rem; /* Larger size for the digits */
  margin-right: 10px;
}

.countdown-label {
  font-size: 3rem; /* Smaller size for the labels */
  margin-right: 20px;
}

/* Hide countdown on mobile view */
@media screen and (max-width: 768px) {
  .countdown-timer {
    display: none;
  }
}
