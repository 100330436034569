body {
    font-family: 'lucida', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #333;
}

.about-section {
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */

}

.acontainer {
    width: 90%;
    height: 100%;
    max-width: 1170px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px; /* Adds spacing between flex items */
}

.left-column {
    flex: 1;
    max-width: 55%; /* Text column takes up more space */
    margin-right: 20px; /* Space between text and image */
}

.right-column {
    align-items: right;
    flex: 1;
    max-width: 55%;
    margin-top: auto;
}

.right-image {
    flex: 1;
    max-width: 40%; /* Image column takes up the rest */
    display: flex;
    justify-content: flex-end;
}

.right-image img {
    max-width: 100%; /* Ensure the image scales properly */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

.left-image {
    flex: 1;
    max-width: 45%;
    margin-right: 20px;
    margin-top: auto;
}

h1 {
    font-family: 'Lucida';
    font-size: 48px;
    color: #000000;
    margin-bottom: 20px;
    font-weight: bold;
}

.left-column p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #000000;
}

.left-column p strong {
    font-weight: normal;
    color: #000000;
}

.info-block {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.icon {
    width: 100px;
    height: 80px;
    border-radius: 12px;
    padding: 15px;
    margin-right: 22px;
    border: 1px solid black;
}

.icon img {
    width: 50px;
    height: 50px;
}

.text h3 {
    font-size: 21px;
    margin-bottom: 10px;
    color: #000000;
    font-weight: bold;
}

.text p {
    font-size: 17px;
    line-height: 1.6;
    color: #000000;
}

/* Mobile responsiveness */
@media (max-width: 750px) {
    .about-section {
        max-width: 100%;
        padding: 40px 10px; /* Reduce padding on smaller screens */
    }

    .acontainer {
        flex-direction: column; /* Stack content vertically on mobile */
        align-items: center;
    }

    .left-column, .right-image, .left-image, .right-column {
        max-width: 100%; /* Full width on small screens */
        margin: 0; /* Remove margins to fit the content properly */

    }

    h1 {
        font-size: 32px; /* Smaller font for headings on mobile */
        text-align: center;
    }

    .left-column p {
        font-size: 16px; /* Adjust text size */
    }

    .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 40px;
    }

    .icon {
        margin-bottom: 15px;
    }


    .text h3, .text p {
        text-align: center;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 28px; /* Even smaller font for very small screens */
    }

    .left-column p {
        font-size: 14px; /* Further reduce text size on very small screens */
    }

    .icon {
        width: 70px; /* Smaller icon */
        padding: 12px; /* Adjust padding */
    }

    .text h3 {
        font-size: 16px; /* Smaller heading size for very small screens */
    }

    .text p {
        font-size: 14px; /* Further reduce paragraph text size */
    }
}
